import React, { useEffect } from 'react';
import ModalComponent from '../../Modal';
import {
  OrderModalActions,
  OrderModalCommonActionsProps,
} from '../OrderModalActions';
import { OrderContext } from '../../../context/OrderContext';
import { OrderStatus, OrderType } from 'models';

export type OrderModalTitle =
  | 'Review Order'
  | 'Resume Quote'
  | 'New Quote'
  | 'Finalize Order - Cover'
  | 'Finalize Order - Liner';

export interface OrderModalProps {
  children?: JSX.Element[] | JSX.Element;
  title: OrderModalTitle;
  isOpen: boolean;
  hasAccess: boolean;
  goToStep: (i: number) => void;
  submitOrder: () => void;
  cancelOrder: () => void;
}

export const OrderModal = (
  props: OrderModalProps & OrderModalCommonActionsProps
): React.ReactElement => {
  const { state } = React.useContext(OrderContext);
  const archived = state?.order?.archived;
  const [nextDisabled, setNextDisabled] = React.useState(true);
  const isAfterQuote =
    state.order?.status === OrderStatus.QuotedOptions ||
    state.order?.status === OrderStatus.ReadyForOrder;

  useEffect(() => {
    // If product step: set disabled button according to validation of that step
    if (props.currentStep === state.steps.indexOf('product')) {
      setNextDisabled(!state.product.isValid);
    }
    if (props.currentStep === state.steps.indexOf('project')) {
      setNextDisabled(!state.project.isValid);
    }
    if (props.currentStep === state.steps.indexOf('features')) {
      setNextDisabled(!state.features.isValid);
    }
    if (props.currentStep === state.steps.indexOf('anchors')) {
      setNextDisabled(!state.anchors.isValid);
    }
    if (props.currentStep === state.steps.indexOf('accessories')) {
      setNextDisabled(!state.accessories.isValid);
    }
    if (props.currentStep === state.steps.indexOf('shipping')) {
      if (isAfterQuote) setNextDisabled(!state.shipping.isValid);
    }
    if (props.currentStep === state.steps.indexOf('review')) {
      if (isAfterQuote) {
        setNextDisabled(
          !state.accessories.isValid ||
            !state.features.isValid ||
            !state.product.isValid ||
            !state.project.isValid ||
            !state.shipping.isValid ||
            (state.order?.type === OrderType.Cover && !state.anchors.isValid)
        );
      } else {
        setNextDisabled(
          !state.accessories.isValid ||
            !state.features.isValid ||
            !state.product.isValid ||
            !state.project.isValid ||
            (state.order?.type === OrderType.Cover && !state.anchors.isValid)
        );
      }
    }
  }, [
    props.currentStep,
    state.product.isValid,
    state.project.isValid,
    state.accessories.isValid,
    state.anchors.isValid,
    state.features.isValid,
    state.shipping.isValid,
  ]);

  return (
    <ModalComponent
      title={props.title}
      isOpen={props.isOpen}
      actions={
        <OrderModalActions
          cancelOrder={props.cancelOrder}
          submitOrder={props.submitOrder}
          currentOrder={props.currentOrder}
          currentStep={props.currentStep}
          isLastStep={props.isLastStep}
          previousStep={props.previousStep}
          finalStep={props.finalStep}
          initialStep={props.initialStep}
          nextStep={props.nextStep}
          nextDisabled={nextDisabled}
          archived={archived}
          hasAccess={props.hasAccess}
        />
      }
      onClose={props.cancelOrder}
      className="nopad"
    >
      {props.children}
    </ModalComponent>
  );
};
