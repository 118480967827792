import { Resource } from './Resource';
import { LabeledResources } from '../sections/orders/Orders';
import { FeatureMeasurements } from './Project';

export enum FeatureType {
  Anchor = 'Anchor',
  AutoCover = 'AutoCover',
  AutoCoverLiner = 'AutoCover Liner',
  Calibration = 'Calibration',
  Cove = 'Cove',
  Decking = 'Decking',
  DivingBoard = 'Diving board',
  DivingObject = 'Diving object',
  Handrail = 'Handrail',
  Liner = 'Liner',
  Perimeter = 'Perimeter',
  Planter = 'Planter',
  SafetyLedge = 'Safety Ledge',
  Slide = 'Slide',
  Spa = 'Spa',
  Steps = 'Steps',
  VinylFeature = 'Vinyl Feature',
  MoldedStep = 'Molded Step',
  Wall = 'Wall',
  WallHeight = 'Wall Height',
  Other = 'Other',
}

export const coverFeatures = [
  FeatureType.AutoCover,
  FeatureType.DivingBoard,
  FeatureType.DivingObject,
  FeatureType.Handrail,
  FeatureType.Planter,
  FeatureType.Slide,
  FeatureType.Spa,
  FeatureType.Steps,
  FeatureType.Wall,
  FeatureType.Other,
];

export const linerFeatures = [
  FeatureType.AutoCoverLiner,
  FeatureType.Cove,
  FeatureType.Liner,
  FeatureType.SafetyLedge,
  FeatureType.VinylFeature,
  FeatureType.WallHeight,
  FeatureType.MoldedStep,
];

export enum HopperShape {
  Square = 'Square',
  Contoured = 'Contoured',
  Roman = 'Roman',
}

export enum MoldedStepFace {
  Radius = 'Radius',
  Straight = 'Staright',
}

export interface Feature {
  measurement?: FeatureMeasurements;
  stepMaterialOption?: string;
  stepMaterial?: string;
  stepFastener?: string;
  stepComment?: string;
  stepStripe?: string;
  stripeWidth?: number;
  differentTreads?: string;
  riserMaterial?: string;
  moldedStepFace?: MoldedStepFace;
  id: string;
  type: FeatureType;
  projectId: string;
  updateTime: Date | null;
  name: string;
  notes: string | null;
  tags: string[] | null;
  isInsidePool?: boolean;
  isRemovable?: boolean;
  isCovered?: boolean;
  scans: any[] | null;
  resources: Resource[] | null;
  length?: number;
}

export const labelFeatures = (
  allFeatures: Feature[],
  showAnchor?: boolean,
  showInProject?: boolean
): { features: LabeledResources[]; decks: LabeledResources[] } => {
  if (allFeatures?.length) {
    const { sortedFeatures, decks } = allFeatures.reduce(
      (acc, feature) => {
        const {
          resources,
          type,
          notes,
          name,
          isRemovable,
          isCovered,
          stepMaterialOption,
          stepMaterial,
          stepFastener,
          stepComment,
          stepStripe,
          stripeWidth,
          moldedStepFace,
          differentTreads,
          riserMaterial,
          id,
        } = feature;
        if (!acc.sortedFeatures[type]) {
          acc.sortedFeatures[type] = [];
        }
        const scannerImages = (resources ?? []).filter(
          (resource) => resource?.type === 'scanner image'
        );
        const dxfNumber = name.replace(/\D/g, '');
        let newNotes = notes;
        if (showInProject && notes && notes?.length > 0) {
          const noteList = notes.split('. ');
          if (noteList.length > 0 && noteList[0].length == 0) {
            newNotes = notes?.replace('.', ' ');
          }
        }
        if (feature?.measurement) {
          if (type === FeatureType.Decking) {
            acc.decks.push({
              id: id,
              type: type,
              label: `Deck ${dxfNumber}`,
              featureName: name,
              images: scannerImages,
              notes: newNotes ?? undefined,
              length: feature?.measurement?.lengthAlongPerimeterIn ?? undefined,
              measurement: feature?.measurement,
            });
          } else if (
            type !== FeatureType.Calibration &&
            type !== FeatureType.Perimeter &&
            (type !== FeatureType.Anchor ||
              (type === FeatureType.Anchor && showAnchor))
          ) {
            acc.sortedFeatures[type].push({
              id: id,
              label: `${type.replace('Vinyl Feature', 'VF')} ${dxfNumber}`,
              type: type,
              featureName: name,
              images: scannerImages,
              notes: newNotes ?? undefined,
              isRemovable: isRemovable,
              stepFastener: stepFastener,
              stepComment: stepComment,
              isCovered: isCovered,
              measurement: feature?.measurement,
              stepMaterialOption: stepMaterialOption,
              stepMaterial: stepMaterial,
              stepStripe: stepStripe,
              stripeWidth: stripeWidth,
              differentTreads: differentTreads,
              riserMaterial: riserMaterial,
              moldedStepFace: moldedStepFace,
            });
          }
        }
        return acc;
      },
      {
        sortedFeatures: {} as { [key: string]: LabeledResources[] },
        decks: [] as LabeledResources[],
      }
    );
    const features = Object.keys(sortedFeatures).reduce((acc, key) => {
      return [...acc, ...sortedFeatures[key]];
    }, [] as LabeledResources[]);

    return { features, decks };
  } else {
    return { features: [], decks: [] };
  }
};
