import React from 'react';

const TransparentBar = ({ height = '1.125rem', width = 'auto' }) => {
  return (
    <div
      style={{
        height: height,
        width: width,
        background: 'transparent',
      }}
    />
  );
};

export default TransparentBar;
