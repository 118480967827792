import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header, Input, Segment, Select } from 'semantic-ui-react';
import DateRangePicker from 'react-semantic-ui-datepickers';
import { SmartTable, TransitionGroup } from 'components';
import { Dealer, Project, ProjectModel, ProjectStatus } from 'models';
import { ProjectService } from '.';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { useAuth, useUI } from 'services';
import Loader from 'components/layout/loader/Loader';
import { USER_MSG } from 'strings';
import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import { ProjectStatusText } from 'models/Project';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './ProjectList.scss';
import { CompanyService } from 'sections/company';
import { ServerOrder } from 'models/Order';
import { checkFeatureAuthorization, Features } from '../../utils';
import { Role } from 'models/Role';

const today = new Date();
const tableType: TableType = 'projects';

const statuses: { [label: string]: string } = {
  Any: '',
  Active: ProjectStatus.ACTIVE,
  Assigned: ProjectStatus.ASSIGNED_TO_BUILDER,
  Review: ProjectStatus.QUOTE_IN_REVIEW,
  Approved: ProjectStatus.QUOTE_APPROVED,
  Ordered: ProjectStatus.ORDERED,
  Completed: ProjectStatus.COMPLETED,
};

export default function ProjectList(): React.ReactElement {
  const [projects, setProjects] = useState([] as Project[]);
  const [dealers, setDealers] = useState([] as Dealer[]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { showError } = useUI();
  const history = useHistory();
  const { projectsTableState, updateTableFilteringState } =
    useSmartTableSortOrder();

  const { user } = useAuth();
  const hasAccess = user?.role !== Role.DESIGN;

  const query = projectsTableState.filters?.get('query') ?? '';
  const status = projectsTableState.filters?.get('status') ?? '';
  const createdDate = projectsTableState.filters?.get('createdDate') ?? '';
  useEffect(() => {
    setQuery('');
    setStatus('');
    setCreatedDate('');
  }, []);

  const setQuery = (value: string) => {
    updateTableFilteringState('query', value, tableType);
  };

  const setStatus = (value: string) => {
    updateTableFilteringState('status', value, tableType);
  };

  const setCreatedDate = (value: string) => {
    updateTableFilteringState('createdDate', value, tableType);
  };

  const filterByCreatedDate = (createdDate: string, project: Project) => {
    if (!createdDate) return true;
    const projectCreatedAt = new Date(project.createdAt);
    const [startDateStr, endDateStr] = createdDate.split('-');
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    endDate.setDate(endDate.getDate() + 1); // Adding one more day to include the selection in the range
    return startDate <= projectCreatedAt && projectCreatedAt <= endDate;
  };

  const filter = useCallback(
    (proj: Project) => {
      const normalize = (str: string) => str?.trim()?.toLowerCase();
      const normedIncludes = (str1: string, str2: string) =>
        normalize(str1)?.includes(normalize(str2));
      return (
        (normedIncludes(proj?.name ?? '', query) ||
          normedIncludes(proj?.dealerName ?? '', query) ||
          normedIncludes(proj?.dealerId ?? '', query) ||
          normedIncludes(proj?.address?.city ?? '', query) ||
          normedIncludes(proj?.customer?.email ?? '', query) ||
          normedIncludes(proj?.customer?.phone ?? '', query) ||
          normedIncludes(proj?.customer?.name ?? '', query) ||
          (proj.projectOrders !== undefined &&
            normedIncludes(proj.projectOrders, query) &&
            proj.projectQuotes !== undefined) ||
          normedIncludes(proj.projectQuotes, query)) &&
        (!status || status === proj.status) &&
        filterByCreatedDate(createdDate, proj)
      );
    },
    [query, status, createdDate]
  );

  const onRowClick = (proj: Project) => {
    history.push(`/projects/${proj.id}`);
  };

  const newProject = () => {
    history.push('/projects/new');
  };

  const fetchProjects = useCallback(async () => {
    try {
      const projects: Project[] = await ProjectService.getAll();
      setProjects(
        projects.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      );
    } catch (e) {
      setLoaded(false);
      showError();
    }
  }, []);

  const fetchDealers = useCallback(async () => {
    try {
      const dealers = await CompanyService.getDealers();
      setDealers(dealers as Dealer[]);
    } catch (error) {
      showError();
    }
  }, [user?.role]);

  useEffect(() => {
    setLoaded(true);
    fetchProjects();
    fetchDealers();
  }, []);

  // TODO: temporarily set all class names to primary until Latham confirms colors
  const getClassName = (status: ProjectStatus) => {
    switch (status) {
      case ProjectStatus.ACTIVE:
        return 'primary';
      case ProjectStatus.COMPLETED:
        return 'primary';
      case ProjectStatus.ORDERED:
        return 'primary';
      case ProjectStatus.QUOTE_IN_REVIEW:
        return 'primary';
      case ProjectStatus.QUOTE_APPROVED:
        return 'primary';
      case ProjectStatus.ASSIGNED_TO_BUILDER:
        return 'primary';
      default:
        return '';
    }
  };

  const convertedProjected = useMemo(
    () =>
      projects.map((project) => ({
        ...project,
        dealerName:
          dealers.find((dealer) => dealer.id === project.dealerId)?.name ?? '',
      })),
    [projects, dealers]
  );
  return (
    <TransitionGroup isVisible={loaded}>
      {projects.length > 0 ? (
        <Segment className="pad project-list">
          <Header size="large">Projects</Header>
          {hasAccess && (
            <Button
              primary
              icon="plus"
              data-cy="add-a-new-project"
              className="main-action"
              content="Add a New Project"
              onClick={newProject}
            />
          )}
          <div className="controls mbottom">
            <Input
              icon="search"
              placeholder="Name, Dealer, City, or Order Number..."
              onChange={(_, { value }) => setQuery(value)}
              value={query}
            />
            <Select
              data-cy="project-dropdown-status"
              placeholder="Status"
              options={Object.entries(statuses).map(([text, value]) => ({
                text: ProjectStatusText(value),
                value,
              }))}
              onChange={(_, { value }) => setStatus(String(value))}
              value={status}
              className="capitalize"
            />
            <DateRangePicker
              type="range"
              maxDate={today}
              onChange={(_, data) => {
                const values = data.value as Date[];
                if (!values) setCreatedDate('');
                if (values?.length == 2) {
                  const formatter = new Intl.DateTimeFormat();
                  const [startDate, endDate] = values;
                  setCreatedDate(
                    `${formatter.format(startDate)}-${formatter.format(
                      endDate
                    )}`
                  );
                }
              }}
            />
          </div>
          <SmartTable
            className="projects-table"
            tableType="projects"
            data={convertedProjected}
            model={ProjectModel}
            excludeProps=" notes address.address2 customer.email customer.phone "
            initialSortProp="status"
            transformProps={{
              createdAt: (val) => val.split('T')[0],
              status: (val) => (
                <div className={'chip ' + getClassName(val)}>
                  {ProjectStatusText(val)}
                </div>
              ),
            }}
            filter={filter}
            onRowClick={onRowClick}
            empty={<Empty />}
          />
        </Segment>
      ) : (
        <>
          {loaded && projects.length === 0 && hasAccess ? (
            <Loader text={USER_MSG.PROJECTS_LOADING} />
          ) : (
            <Loader text={USER_MSG.PROJECTS_NOT_FOUND}>
              <Button
                data-cy="add-a-new-project"
                primary
                icon="plus"
                style={{ margin: 'auto' }}
                content="Add a New Project"
                onClick={newProject}
              />
            </Loader>
          )}
        </>
      )}
    </TransitionGroup>
  );
}
