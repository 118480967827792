import React, { useRef, useState } from 'react';
import { Tab } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { SmartTable } from 'components';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { Feature, Project, Resource } from 'models';
import { labelFeatures } from '../../models/Feature';
import { LabeledResources } from '../orders/Orders';
import { useFeatureFlags } from '../../services';
import { FeatureGallery } from './FeatureGallery';

interface ProjectDebugProps {
  project: Project;
}

export default function ProjectTable({
  project,
}: ProjectDebugProps): React.ReactElement {
  const { features: featuresRaw, scans, resources } = project;
  const { decks, features } = labelFeatures(
    featuresRaw as Feature[],
    true,
    true
  );
  // we do not want to display the id, isRemovable, or isCovered property for features or decks
  features.forEach((el) => {
    delete el.id;
    delete el.isRemovable;
    delete el.stepFastener;
    delete el.isCovered;
    delete el.measurement;
    delete el.stepMaterialOption;
    delete el.stepMaterial;
    delete el.stepStripe;
    delete el.stripeWidth;
    delete el.differentTreads;
    delete el.riserMaterial;
    delete el.stepFastener;
    delete el.moldedStepFace;
  });
  decks.forEach((el) => {
    delete el.id;
    delete el.isRemovable;
    delete el.stepFastener;
    delete el.isCovered;
    delete el.measurement;
    delete el.stepMaterialOption;
    delete el.stepMaterial;
    delete el.stepStripe;
    delete el.stripeWidth;
    delete el.differentTreads;
    delete el.riserMaterial;
    delete el.stepFastener;
    delete el.moldedStepFace;
  });

  const flags = useFeatureFlags();

  const [galleryVisible, setIsGalleryVisible] = useState(false);
  const onImageClick = (
    ref: React.MutableRefObject<ImageGallery | null>,
    index: number
  ) => {
    ref.current?.slideToIndex(index);
    ref.current?.fullScreen();
  };

  const aggregateResources = (sources?: any[]): Resource[] =>
    sources?.reduce((acc, source) => {
      const resourceList = source.resources.map((resource: Resource) => {
        const isDeck = decks.find((deck) =>
          deck.images.find((image) => image.id === resource.id)
        );
        const isFeature = features.find((feature) =>
          feature.images.find((image) => image.id === resource.id)
        );

        return {
          featureName: isDeck?.featureName ?? isFeature?.featureName ?? '',
          ...resource,
          scanType: source.type,
        };
      });
      return [...acc, ...resourceList];
    }, []);

  const renderSmartTable = (
    resources?: Resource[] | LabeledResources[]
  ): React.ReactElement => (
    <SmartTable
      //@ts-ignore
      data={resources ?? []}
      excludeProps=" createdAt updatedAt projectId "
      initialSortProp="type"
      transformProps={{
        updateTime: (val) => val?.split('T')[0],
        images: (images) => {
          return (
            <FeatureGallery
              images={images}
              onImageClick={onImageClick}
              galleryVisible={galleryVisible}
              setIsGalleryVisible={setIsGalleryVisible}
            />
          );
        },
        url: (val) =>
          val ? (
            <a
              href={val}
              target="_blank"
              rel="noreferrer"
              download={`${val.name}-${val.updatedTime}`}
            >
              Download
            </a>
          ) : (
            'Unavailable'
          ),
      }}
      empty={<Empty />}
      className="scroll"
    />
  );

  const panes = [
    {
      menuItem: 'Features',
      render: () => (
        <Tab.Pane attached={false}>{renderSmartTable(features)}</Tab.Pane>
      ),
    },
    {
      menuItem: 'Decks',
      render: () => (
        <Tab.Pane attached={false}>{renderSmartTable(decks)}</Tab.Pane>
      ),
    },
  ];

  if (flags.showRawResourcesView) {
    panes.push({
      menuItem: 'Raw Data',
      render: () => (
        <Tab.Pane attached={false}>
          {renderSmartTable(aggregateResources(scans))}
        </Tab.Pane>
      ),
    });
    panes.push({
      menuItem: 'Resources',
      render: () => (
        <Tab.Pane attached={false}>{renderSmartTable(resources)}</Tab.Pane>
      ),
    });
  }

  return <Tab menu={{ pointing: true }} panes={panes} />;
}
