import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Project, Resource, ProjectModel } from 'models';
import { Segment, Header, Card, Loader, Image } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { TransitionGroup } from 'components';
import { ProjectService } from '.';
import { useUI, useAuth, hasSuperAccess, useFeatureFlags } from 'services';
import { PoolShapeSelect } from 'components/project/PoolShapeSelect';
import { OrderType, PoolShapeType } from 'models/Order';
import { ModelInstance } from 'models/Model';
import * as PoolShapeSvg from 'models/PoolShapeImages';
import { PoolShapeOptions } from 'models/PoolShapeOptions';
import { USER_MSG } from 'strings';
import 'components/project/PoolShapeSelect.scss';
import './ProjectMeasurement.scss';
import { checkFeatureAuthorization, Features } from '../../utils';
import { ReactComponent as Cross } from 'res/img/generate_drawing.svg';

const PROJECT_MEASUREMENT = 'Project Measurement';
const TAKEN_ON = 'Taken On';
const NO_DATE_CAPTURED = 'No Date Captured';
const NO_IMAGE_CAPTURED = 'No Image Captured';
interface ProjectMeasurementProps {
  project: Project;
  fetchProject: () => void;
  updateProject: React.Dispatch<React.SetStateAction<ModelInstance<Project>>>;
}

export default function ProjectMeasurement({
  project,
  fetchProject,
  updateProject,
}: ProjectMeasurementProps): React.ReactElement {
  const { user } = useAuth();
  const [coverDxfObject, setCoverDxfObject] = useState<Resource>();
  const [linerDxfObject, setLinerDxfObject] = useState<Resource>();
  const [coverImageReference, setCoverImageReference] = useState<string>();
  const [linerImageReference, setLinerImageReference] = useState<string>();
  const [coverBase64Img, setCoverBase64Img] = useState<string>();
  const [linerBase64Img, setLinerBase64Img] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showCoverGallery, setCoverShowGallery] = useState(false);
  const [showLinerGallery, setLinerShowGallery] = useState(false);
  const { showError, showSuccess } = useUI();
  const coverGallery = useRef<ImageGallery | null>(null);
  const linerGallery = useRef<ImageGallery | null>(null);
  const flags = useFeatureFlags();
  const showLinerUi = flags.showLinerUi;

  const DownloadAllowed = ({
    isDownloadAllowed,
    href,
    downloadUrl,
    children,
  }: {
    isDownloadAllowed: boolean;
    href: string | undefined;
    downloadUrl: string;
    children: React.ReactNode;
  }) => {
    return isDownloadAllowed ? (
      <a href={href} rel="noreferrer" download={downloadUrl}>
        {children}
      </a>
    ) : (
      <a>{children}</a>
    );
  };

  const getPoolShapeName = () => {
    const selectedShape = PoolShapeOptions.filter((el) => {
      return (
        el.value === project?.poolDetails?.poolShape?.baseName &&
        project?.poolDetails?.poolShape?.orientation?.includes(el.orientation)
      );
    });
    return selectedShape[0]?.text;
  };

  const getPoolShapeObj = (shapeName: string) => {
    const shape = shapeName;
    const selectedShape = PoolShapeOptions.filter((el) => {
      return el.text === shape;
    });
    return selectedShape[0];
  };
  const [poolShape, setPoolShape] = useState(getPoolShapeName());
  const [poolShapeObj, setPoolShapeObj] = useState(
    getPoolShapeObj(getPoolShapeName())
  );
  const getDxfResource = useCallback(
    (cover: boolean) => {
      if (cover) {
        const resources = project?.resources?.filter(
          (resource) =>
            resource.type.toString() === 'dxf' &&
            resource.status === 'uploaded' &&
            !resource.name.includes('Liner')
        );
        if (resources && resources?.length > 0) {
          return resources[0];
        }
      } else {
        const resources = project?.resources?.filter(
          (resource) =>
            resource.type.toString() === 'dxf' &&
            resource.status === 'uploaded' &&
            resource.name.includes('Liner')
        );
        if (resources && resources?.length > 0) {
          return resources[0];
        }
      }
      return undefined;
    },
    [project]
  );

  const rerunDXF = useCallback(async () => {
    try {
      setLoading(true);
      await ProjectService.generateDXF(project.id)
        .then(() => {
          setLoading(false);
          fetchProject();
        })
        .catch(async (error) => {
          await saveProject();
          setLoading(false);
          showError({ title: USER_MSG.ERROR_DXF.title, msg: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      showError();
      console.error(error);
    }
  }, [project]);

  useEffect(() => {
    setCoverDxfObject((getDxfResource(true) || {}) as Resource);
    const imgResource = project?.resources?.filter(
      (resource) =>
        resource.type.toString() === 'dxf image' &&
        resource.status === 'uploaded' &&
        !resource.name.includes('Liner')
    );
    if (imgResource) {
      if (imgResource && imgResource.length > 0) {
        setCoverImageReference(imgResource[0].url);
        if (
          imgResource[0]?.id &&
          checkFeatureAuthorization(Features.GetBase64Image, user?.role)
        ) {
          getBase64Image(imgResource[0].id, true);
        }
      }
    }
  }, [getDxfResource, setCoverImageReference, project]);

  useEffect(() => {
    if (showLinerUi) {
      setLinerDxfObject((getDxfResource(false) || {}) as Resource);
    }
    const imgResource = project?.resources?.filter(
      (resource) =>
        resource.type.toString() === 'dxf image' &&
        resource.name.includes('Liner')
    );
    if (imgResource && imgResource?.length > 0) {
      if (showLinerUi) {
        setLinerImageReference(imgResource[0].url);
      }
      if (
        imgResource[0]?.id &&
        checkFeatureAuthorization(Features.GetBase64Image, user?.role)
      ) {
        getBase64Image(imgResource[0].id, false);
      }
    }
  }, [getDxfResource, setLinerImageReference, project]);

  const getBase64Image = async (resourceId: string, cover: boolean) => {
    const base46Data = await ProjectService.getBase64Image(resourceId);
    if (cover) {
      setCoverBase64Img(base46Data);
    } else {
      if (showLinerUi) {
        setLinerBase64Img(base46Data);
      }
    }
  };

  const onError = useCallback(() => {
    showError();
  }, [showError]);

  const onCancel = () => {
    const shapeName = getPoolShapeName();
    setPoolShape(shapeName);
    setPoolShapeObj(getPoolShapeObj(shapeName));
    setIsOpen(false);
  };

  const saveProject = async () => {
    delete project.dealerName;
    setPoolShapeObj(getPoolShapeObj(poolShape));
    const selectedShape = PoolShapeOptions.filter((el) => {
      return el.text === poolShape;
    });
    const newProject = {
      ...project,
      poolDetails: {
        ...project?.poolDetails,
        poolShape: poolShape
          ? {
              ...project?.poolDetails?.poolShape,
              baseName: selectedShape[0].value,
              baseCode: selectedShape[0].code,
              orientation: selectedShape[0].orientation,
              geometric:
                selectedShape[0].type === PoolShapeType.Geometric
                  ? selectedShape[0].type
                  : null,
              freeform:
                selectedShape[0].type === PoolShapeType.Freeform
                  ? selectedShape[0].type
                  : null,
            }
          : undefined,
        poolShapeType: selectedShape[0]?.type,
      },
    };
    const data = { ...newProject };
    delete data.features;
    delete data.resources;
    delete data.scans;
    delete data.orders;

    await ProjectService.set(data, 'PATCH', false)
      .then(() => {
        project.resources && project.resources?.length > 0
          ? null
          : showSuccess(USER_MSG.SUCCESS_GENERIC);
      }, onError)
      .catch(async (error) => {
        showError({
          title: USER_MSG.ERROR_SAVING_PROJECT.title,
          msg: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    updateProject(ProjectModel({ ...newProject }));
  };

  const onSubmit = async () => {
    await saveProject();
    if (project.resources && project.resources?.length > 0) {
      await rerunDXF();
    }
    setIsOpen(false);
  };

  const handleOpenModal = (): void => {
    setIsOpen(true);
  };

  return (
    <TransitionGroup isVisible={!!coverDxfObject}>
      <Segment className="pad">
        <Header size="large">{PROJECT_MEASUREMENT}</Header>
        <div className="project-measurement-card-group">
          <Card className="select-pool-card">
            <Card.Header className="shape-card-header">
              {poolShapeObj?.text ?? 'Pool Shape'}
            </Card.Header>
            <div
              className="shape-card-container"
              onClick={
                checkFeatureAuthorization(Features.SelectPoolShape, user?.role)
                  ? handleOpenModal
                  : undefined
              }
            >
              <>
                {poolShapeObj?.image?.src ? (
                  <Image
                    className="shape-card-image"
                    src={poolShapeObj?.image?.src}
                  />
                ) : (
                  <Cross className="shape-card-image-cross" />
                )}
                {checkFeatureAuthorization(
                  Features.SelectPoolShape,
                  user?.role
                ) && <div className="select-pool-shape">Generate Drawing</div>}
              </>
            </div>
            <PoolShapeSelect
              value={poolShape}
              onChange={setPoolShape}
              options={PoolShapeOptions}
              isOpen={isOpen}
              poolShape={getPoolShapeName()}
              onCancel={onCancel}
              onSubmit={onSubmit}
              loading={loading}
            />
          </Card>
          <div className="feature-card-group">
            <div className={'cover-card-group'}>
              {coverDxfObject?.id && (
                <Header size="medium" className="feature-header">
                  {OrderType.Cover}
                </Header>
              )}
              <Card>
                <Card.Header>
                  {coverDxfObject?.id && hasSuperAccess(user) && (
                    <DownloadAllowed
                      isDownloadAllowed={checkFeatureAuthorization(
                        Features.DownloadDxf,
                        user?.role
                      )}
                      href={coverDxfObject.url}
                      downloadUrl={`${coverDxfObject.id}-${
                        coverDxfObject.updateTime?.split('T')[0]
                      }`}
                    >
                      Download Cover DXF
                    </DownloadAllowed>
                  )}
                </Card.Header>
                {!loading ? (
                  <Card.Content>
                    <Card.Meta>
                      {TAKEN_ON}
                      <Card.Description>
                        {coverDxfObject?.updateTime?.split('T')[0] ||
                          NO_DATE_CAPTURED}
                      </Card.Description>
                      {hasSuperAccess(user) &&
                        project?.poolDetails?.measurements && (
                          <button
                            className="ui primary button mtop"
                            onClick={rerunDXF}
                          >
                            {coverDxfObject?.updateTime
                              ? 'Regenerate'
                              : 'Generate'}
                          </button>
                        )}
                    </Card.Meta>
                  </Card.Content>
                ) : (
                  <Loader active size="small" content="Loading" />
                )}
              </Card>
              <Card className="drawing-for-file-container">
                {coverImageReference && (
                  <Card.Header className="drawing-for-file-header">
                    <a
                      href={`data:image/png;base64,${coverBase64Img}`}
                      rel="noreferrer"
                      download={`${project?.name}-drawing-for-file-cover`}
                    >
                      Download Cover Drawing for File
                    </a>
                  </Card.Header>
                )}
                {!loading ? (
                  <Card.Content className="drawing-for-file-image">
                    {coverImageReference ? (
                      <img
                        className="pointer fullh fullw"
                        src={coverImageReference}
                        alt={coverImageReference}
                        onClick={() => {
                          coverGallery.current?.slideToIndex(1);
                          coverGallery.current?.fullScreen();
                        }}
                      />
                    ) : project?.poolDetails?.measurements ? (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{ textAlign: 'center' }}>
                          <i
                            style={{ fontSize: '5em', color: '#db2828' }}
                            className="exclamation icon"
                          ></i>
                          <p>
                            An error occurred when generating the Cover Drawing
                            For File. Please contact your administrator
                          </p>
                        </div>
                      </div>
                    ) : (
                      <Card.Meta>
                        <Card.Description>{NO_IMAGE_CAPTURED}</Card.Description>
                      </Card.Meta>
                    )}
                    <div
                      style={{
                        visibility: showCoverGallery ? 'visible' : 'hidden',
                      }}
                    >
                      <ImageGallery
                        ref={coverGallery}
                        items={[
                          {
                            original: coverImageReference || '',
                            thumbnail: coverImageReference,
                          },
                        ]}
                        onScreenChange={setCoverShowGallery}
                        useBrowserFullscreen={false}
                        additionalClass={
                          'hidden' + showCoverGallery ? '' : 'show'
                        }
                        showPlayButton={false}
                        showThumbnails={false}
                        showFullscreenButton={showCoverGallery}
                      />
                    </div>
                  </Card.Content>
                ) : (
                  <Loader active size="small" content="Loading" />
                )}
              </Card>
            </div>
            {showLinerUi && linerDxfObject?.id && (
              <div className="vertical-line"></div>
            )}
            <div className="liner-card-group">
              {showLinerUi && linerDxfObject?.id && (
                <>
                  <Header size="medium" className="feature-header">
                    {OrderType.Liner}
                  </Header>
                  <Card>
                    <Card.Header>
                      {linerDxfObject?.id && hasSuperAccess(user) && (
                        <DownloadAllowed
                          isDownloadAllowed={checkFeatureAuthorization(
                            Features.DownloadDxf,
                            user?.role
                          )}
                          href={linerDxfObject.url}
                          downloadUrl={`${linerDxfObject.id}-${
                            linerDxfObject.updateTime?.split('T')[0]
                          }`}
                        >
                          Download Liner DXF
                        </DownloadAllowed>
                      )}
                    </Card.Header>
                    {!loading ? (
                      <Card.Content>
                        <Card.Meta>
                          {TAKEN_ON}
                          <Card.Description>
                            {linerDxfObject?.updateTime?.split('T')[0] ||
                              NO_DATE_CAPTURED}
                          </Card.Description>
                          {hasSuperAccess(user) &&
                            project?.poolDetails?.measurements && (
                              <button
                                className="ui primary button mtop"
                                onClick={rerunDXF}
                              >
                                {linerDxfObject?.updateTime
                                  ? 'Regenerate'
                                  : 'Generate'}
                              </button>
                            )}
                        </Card.Meta>
                      </Card.Content>
                    ) : (
                      <Loader active size="small" content="Loading" />
                    )}
                  </Card>
                  <Card className="drawing-for-file-container">
                    {linerImageReference && (
                      <Card.Header className="drawing-for-file-header">
                        <a
                          href={`data:image/png;base64,${linerBase64Img}`}
                          rel="noreferrer"
                          download={`${project?.name}-drawing-for-file-liner`}
                        >
                          Download Liner Drawing for File
                        </a>
                      </Card.Header>
                    )}
                    {!loading ? (
                      <Card.Content className="drawing-for-file-image">
                        {linerImageReference ? (
                          <img
                            className="pointer fullh fullw"
                            src={linerImageReference}
                            alt={linerImageReference}
                            onClick={() => {
                              linerGallery.current?.slideToIndex(1);
                              linerGallery.current?.fullScreen();
                            }}
                          />
                        ) : project?.poolDetails?.measurements ? (
                          <div
                            style={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <div style={{ textAlign: 'center' }}>
                              <i
                                style={{ fontSize: '5em', color: '#db2828' }}
                                className="exclamation icon"
                              ></i>
                              <p>
                                An error occurred when generating the Drawing
                                For File. Please contact your administrator
                              </p>
                            </div>
                          </div>
                        ) : (
                          <Card.Meta>
                            <Card.Description>
                              {NO_IMAGE_CAPTURED}
                            </Card.Description>
                          </Card.Meta>
                        )}
                        <div
                          style={{
                            visibility: showLinerGallery ? 'visible' : 'hidden',
                          }}
                        >
                          <ImageGallery
                            ref={linerGallery}
                            items={[
                              {
                                original: linerImageReference || '',
                                thumbnail: linerImageReference,
                              },
                            ]}
                            onScreenChange={setLinerShowGallery}
                            useBrowserFullscreen={false}
                            additionalClass={
                              'hidden' + showLinerGallery ? '' : 'show'
                            }
                            showPlayButton={false}
                            showThumbnails={false}
                            showFullscreenButton={showLinerGallery}
                          />
                        </div>
                      </Card.Content>
                    ) : (
                      <Loader active size="small" content="Loading" />
                    )}
                  </Card>
                </>
              )}
            </div>
          </div>
        </div>
      </Segment>
    </TransitionGroup>
  );
}
