import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Input,
  Segment,
  Header,
  Select,
  Form,
  Confirm,
} from 'semantic-ui-react';
import { Modal, TransitionGroup } from 'components';
import { Modal as NativeModal } from 'semantic-ui-react';
import { Employee, EmployeeModel, EmployeeStatus, Dealer, User } from 'models';
import { SmartTable, FieldGroup } from 'components';
import { EmployeeService, CompanyService } from '.';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import { useUI, useAuth, hasAccess, hasSuperAccess } from 'services';
import Loader from 'components/layout/loader/Loader';
import { USER_MSG } from 'strings';
import useSmartTableSortOrder, { TableType } from 'context/SmartTableContext';
import './Employee.scss';
import { Role } from 'models/Role';

const HEADER_TITLE = 'Employees';
const statuses: { [label: string]: string } = {
  Any: '',
  Active: EmployeeStatus.ACTIVE,
  Inactive: EmployeeStatus.INACTIVE,
};

export default function EmployeeList(props: any): React.ReactElement {
  const tableType: TableType = 'employees';
  const { showTOSStatus } = props;
  const [modalState, setModalState] = useState(false);
  const [employee, setEmployee] = useState(EmployeeModel({} as Employee));
  const [employees, setEmployees] = useState([] as Employee[]);
  const [dealers, setDealers] = useState([] as Dealer[]);
  const { devMode, showError, showSuccess } = useUI();
  const { user } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState({
    loading: false,
    confirm: null as {
      title: string;
      msg: string;
      action: () => void;
      ok?: string;
    } | null,
  });
  const hasEditAccess =
    user?.role !== Role.DESIGN &&
    user?.role !== Role.USER &&
    user?.role !== Role.MEASURE_LITE &&
    user?.role !== Role.SALES;
  const hasAddAccess =
    user?.role !== Role.DESIGN &&
    user?.role !== Role.SALES &&
    user?.role !== Role.USER &&
    user?.role !== Role.MEASURE_LITE;
  const hasDeleteAccess =
    user?.role !== Role.DESIGN &&
    user?.role !== Role.SALES &&
    user?.role !== Role.USER &&
    user?.role !== Role.CUST_SERV &&
    user?.role !== Role.MEASURE_LITE;

  const { employeesTableState, updateTableFilteringState } =
    useSmartTableSortOrder();

  const query = employeesTableState.filters?.get('query') ?? '';
  const status = employeesTableState.filters?.get('status') ?? '';

  const setQuery = (value: string) => {
    updateTableFilteringState('query', value, tableType);
  };

  const setStatus = (value: string) => {
    updateTableFilteringState('status', value, tableType);
  };

  const fetchEmployees = useCallback(async () => {
    try {
      setIsLoading(true);
      const [employees, dealers] = await Promise.all([
        EmployeeService.getAll(),
        CompanyService.getDealers(),
      ]);
      setDealers(dealers as Dealer[]);
      setEmployees(employees as Employee[]);
    } catch (error) {
      showError();
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchDealerEmployees = useCallback(async () => {
    try {
      setIsLoading(true);
      const [employees, dealers] = await Promise.all([
        EmployeeService.getDealersById(props?.dealerId),
        CompanyService.getDealers(),
      ]);
      setDealers(dealers as Dealer[]);
      setEmployees(employees);
    } catch (error) {
      showError();
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getEmployees = props?.dealerId ? fetchDealerEmployees : fetchEmployees;

  const companyMappings = useCallback(
    () =>
      dealers
        .map((dealer: Dealer) => ({
          key: dealer.id,
          value: dealer.id,
          text: `${dealer.name} (${dealer.rewardsId})`,
        }))
        .sort((a, b) =>
          a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())
        ),
    [dealers]
  );

  const addEmployee = useCallback(() => {
    if (!employee.isValid()) {
      showError(USER_MSG.ERROR_INVALID);
      return;
    }

    //@ts-ignore
    delete employee.data.createdAt;
    delete employee.data.dealerName;
    setLoading(true);
    EmployeeService.add(employee.data)
      .then(() => {
        setModalState(false);
        getEmployees();
        showSuccess();
      })
      .catch((error) => {
        showError({
          title: 'User could not be created',
          msg: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [employee, fetchEmployees, fetchDealerEmployees, showError, showSuccess]);

  const editEmployee = useCallback(() => {
    setLoading(true);
    const employeeData = { ...employee.data, dealerName: undefined };
    EmployeeService.set(employeeData, 'PATCH', false)
      .then(() => {
        setModalState(false);
        getEmployees();
        showSuccess();
      })
      .catch((e) => {
        switch (e.statusCode) {
          case 409:
            showError(USER_MSG.ERROR_EMAIL_CONFLICTS);
            break;
          case 400:
            showError(USER_MSG.ERROR_INVALID_DATA);
            break;
          default:
            showError();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [employee, showSuccess, showError, fetchEmployees, fetchDealerEmployees]);

  const deleteEmployee = useCallback(() => {
    setModalState(false);
    EmployeeService.del(employee.data.id)
      .then(() => {
        getEmployees();
        showSuccess();
      })
      .catch(() => {
        showError();
      })
      .finally(() => {
        setForm({ loading: false, confirm: null });
      });
  }, [employee, showSuccess, showError, fetchEmployees, fetchDealerEmployees]);

  const CONFIRM = {
    DELETE: {
      title: USER_MSG.CONFIRM_DELETE_NAME.title.replace('{name}', 'Employee'),
      msg: USER_MSG.CONFIRM_DELETE_NAME.msg.replace(
        '{name}',
        employee.data.name
      ),
      action: deleteEmployee,
      ok: 'Delete',
    },
  };

  const handleOpenModal = (employee: Employee, isNew = false): void => {
    setModalState(true);
    getEmployees();
    setEmployee(
      !isNew
        ? EmployeeModel(employee)
        : EmployeeModel({
            isActive: false,
            role: props?.dealerId ? 'admin' : '',
            dealerId: props?.dealerId ?? user?.dealerId,
          } as Employee)
    );
  };

  const filter = useCallback(
    (employee: Employee) => {
      const normalize = (str: string) => (str ? str.trim().toLowerCase() : '');
      const normedIncludes = (str1: string, str2: string) =>
        normalize(str1).includes(normalize(str2));
      return (
        (normedIncludes(employee.name, query) ||
          normedIncludes(employee.email, query) ||
          normedIncludes(employee.phone, query) ||
          normedIncludes(employee.role, query)) &&
        (!status ||
          status ===
            (employee.isActive
              ? EmployeeStatus.ACTIVE
              : EmployeeStatus.INACTIVE))
      );
    },
    [query, status]
  );

  useEffect(() => {
    setLoaded(true);
    getEmployees();
  }, [fetchEmployees, fetchDealerEmployees, setLoaded]);

  useEffect(() => {
    getEmployees();
  }, []);

  const renderModelControls: React.ReactElement = useMemo(() => {
    return (
      <div className="controls">
        <Button
          basic
          onClick={() => setModalState(false)}
          content="Cancel"
          disabled={loading}
        />
        {hasDeleteAccess && employee.data.id && (
          <Button
            negative
            onClick={() => setForm({ loading: false, confirm: CONFIRM.DELETE })}
            content="Delete"
            disabled={loading}
          />
        )}
        {hasEditAccess && (
          <Button
            className="control-right"
            primary
            onClick={() => (employee.data.id ? editEmployee() : addEmployee())}
            content={employee.data.id ? 'Update' : 'Submit'}
            disabled={loading}
          />
        )}
      </div>
    );
  }, [addEmployee, setModalState, editEmployee, employee, loading]);

  const convertedEmployees = useMemo(
    () =>
      employees.map((employee) => ({
        ...employee,
        dealerName:
          companyMappings().find(
            (company) => company.value === employee.dealerId
          )?.text ?? '',
      })),
    [employees, dealers]
  );

  const addNewEmployeeLoader = (
    <Loader
      topClass={props?.dealerId ? 'loader-top' : ''}
      text={USER_MSG.EMPLOYEES_NOT_FOUND}
    >
      {hasAddAccess && (
        <Button
          primary
          icon="plus"
          className="primary"
          content="Add a New Employee"
          onClick={() => handleOpenModal(employee.data, true)}
        />
      )}
    </Loader>
  );

  const loadingLoader = (
    <Loader
      topClass={props?.dealerId ? 'loader-top' : ''}
      text={USER_MSG.EMPLOYEES_LOADING}
    />
  );

  return (
    <TransitionGroup isVisible={loaded}>
      <Modal
        isOpen={modalState}
        size="small"
        title={employee.data.id ? 'Edit Employee' : 'New Employee'}
        actions={renderModelControls}
      >
        <Form
          className="row"
          style={{ gap: '22px', justifyContent: 'space-evenly' }}
        >
          <Confirm
            className="employee-delete-modal"
            size="mini"
            open={!!form.confirm}
            header={form.confirm?.title}
            content={form.confirm?.msg}
            onConfirm={form.confirm?.action}
            confirmButton={form.confirm?.ok || 'OK'}
            onCancel={() => setForm({ loading: false, confirm: null })}
          />
          <FieldGroup
            fields="name phone isActive"
            disabled={!hasEditAccess}
            model={employee}
          />
          <div>
            {devMode ||
            (!!user &&
              (hasAccess(user, Role.BAI_ADMIN) ||
                hasAccess(user, Role.LATHAM_ADMIN))) ? (
              <div className="field">
                <label>Company</label>
                <Select
                  placeholder={
                    companyMappings().find(
                      (company) => company.value === employee.data.dealerId
                    )?.text || 'Select Company'
                  }
                  options={companyMappings()}
                  onChange={(_, { value }: any) => {
                    setEmployee(
                      EmployeeModel({ ...employee.data, dealerId: value })
                    );
                  }}
                  value={employee.data.dealerId}
                  forceSelection={false}
                  selectOnBlur={false}
                  search
                  data-cy="employee-status-select"
                />
              </div>
            ) : null}
            <FieldGroup
              fields="role email"
              disabled={!hasEditAccess}
              model={employee}
            />
          </div>
        </Form>
        <NativeModal basic open={loading}>
          <NativeModal.Content className="scroll">
            <div className="ui large text loader">Processing...</div>
          </NativeModal.Content>
        </NativeModal>
      </Modal>
      {employees?.length > 0 ? (
        <Segment className="employee-list pad">
          <Header size="large">{HEADER_TITLE}</Header>
          {hasAddAccess && (
            <Button
              primary
              icon="plus"
              className="main-action"
              content="Add a New Employee"
              onClick={() => handleOpenModal(employee.data, true)}
            />
          )}
          <div className="controls mbottom">
            <Input
              icon="search"
              placeholder="Name, Email or Phone..."
              onChange={(_, { value }) => setQuery(value)}
              data-cy="employee-filter-input"
              value={query}
            />
            <Select
              placeholder="Status"
              options={Object.entries(statuses).map(([text, value]) => ({
                text,
                value,
              }))}
              onChange={(_, { value }) => setStatus(String(value))}
              data-cy="employee-status-select"
              value={status}
            />
          </div>
          <SmartTable
            tableType="employees"
            data={convertedEmployees}
            model={EmployeeModel}
            initialSortProp="status"
            filter={filter}
            excludeProps={`${showTOSStatus ? '' : ' signedTOS '}`}
            transformProps={{
              createdAt: (val) => val.split('T')[0],
              isActive: (val) => (
                <div className={'chip ' + (val === true ? 'primary' : '')}>
                  {val ? EmployeeStatus.ACTIVE : EmployeeStatus.INACTIVE}
                </div>
              ),
              signedTOS: (val) => (val ? 'Signed' : 'Not Signed'),
            }}
            onRowClick={handleOpenModal}
            empty={<Empty />}
            className="scroll"
          />
        </Segment>
      ) : isLoading ? (
        loadingLoader
      ) : (
        addNewEmployeeLoader
      )}
    </TransitionGroup>
  );
}
