import {
  Project,
  ProjectStatus,
  User,
  Dealer,
  PurchasePartner,
  Resource,
  FeatureType,
} from 'models';
import { pickOne, random } from 'utils';

const firstNames =
  'Dexter Steven Dipper Finn Gary Larry Bart Garnet Rose Connie Lisa Hilda Mabel Carmen'.split(
    ' '
  );
const lastNames =
  'Fake Spam Dev Stage Prod React Vue Svelte Parker Stark Wayne Kent Bond Quest SanDiego'.split(
    ' '
  );
const cities =
  'Lorwyn Innistrad Mirrodin Dominaria Zendikar Ravnica Eldraine Ixalan Amonkhet Kaladesh Shandalar'.split(
    ' '
  );
const mailProviders = 'google yahoo live msn icq napster'.split(' ');

export const user: User = {
  id: 'dummy-user',
  name: 'Dummy User',
  email: 'dummy@user.com',
  phone: '12345678',
  dealerId: 'dummy-dealer',
  // @ts-ignore
  role: 'admin',
  isActive: true,
};

export const projects: Project[] = Array(100)
  .fill(0)
  .map((_, i) => {
    const p: Project = {
      id: '' + i,
      userId: 'dummy-user',
      dealerId: 'dummy-dealer',
      dealerName: 'dummy-dealer-name',
      projectOrders: 'dummy-orders',
      projectQuotes: 'dummy-quotes',
      name: 'Project ' + i,
      customerNumber: 'dummy-subDealerNumber',
      createdAt: new Date().toISOString(),
      status: i % 3 ? ProjectStatus.ACTIVE : ProjectStatus.COMPLETED,
      address: {
        address1:
          pickOne(lastNames) +
          ' ' +
          pickOne('Street Avenue Way Road Park'.split(' ')),
        address2: '',
        city: pickOne(cities),
        state: 'Multiverse',
        postalcode: '' + random(5),
      },
      customer: {
        name: pickOne(firstNames) + ' ' + pickOne(lastNames),
        phone: '' + random(8),
        email: '',
      },
      notes: '',
      resources: Array(random(1))
        .fill(0)
        .map((_, i) => {
          return {
            id: '' + i,
            name: 'abc123',
            revision: 0,
            projectId: '' + random(2),
            captureSource: 'portal',
            type: 'user image',
            mimeType: 'image/png',
            status: 'uploaded',
            url: 'https://picsum.photos/800/450?random=' + random(2),
            updateTime: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
        }),
      features: [
        {
          id: i.toString(),
          type: FeatureType.DivingBoard,
          projectId: '',
          updateTime: new Date(),
          name: '',
          notes: '',
          tags: [],
          scans: [],
          resources: Array(random(1))
            .fill(0)
            .map((_, i) => {
              return {
                id: '' + i,
                revision: 0,
                projectId: '' + random(2),
                captureSource: 'portal',
                type: 'user image',
                mimeType: 'image/png',
                status: 'uploaded',
                url: 'https://picsum.photos/800/450?random=' + random(2),
                updateTime: new Date().toISOString(),
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              } as Resource;
            }),
        },
      ],
      poolDetails: {
        measurements: undefined,
        surfaceArea: 4242,
        poolShapeType: 'freeform',
        poolShape: {
          baseName: 'Freeform (Custom)',
          baseCode: '88',
          freeform: 'freeform',
        },
      },
      scans: [
        {
          resources: Array(random(1))
            .fill(0)
            .map((_, i) => {
              return {
                id: '' + i,
                revision: 0,
                projectId: '' + random(2),
                captureSource: 'portal',
                type: 'user image',
                mimeType: 'image/png',
                status: 'uploaded',
                url: 'https://picsum.photos/800/450?random=' + random(2),
                updateTime: new Date().toISOString(),
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              };
            }),
        },
      ],
    };
    // @ts-ignore
    p.customer.email =
      p.customer.name.toLowerCase().replace(' ', '_') +
      '@' +
      pickOne(mailProviders) +
      '.com';
    return p;
  });

export const distributors: PurchasePartner[] = Array(3)
  .fill(0)
  .map((_, i) => {
    const dd: PurchasePartner = {
      isDefault: 'No',
      type: 'distributor',
      name: pickOne(lastNames),
      code: '' + random(8),
      branchCode: '' + random(3),
      createdAt: new Date().toISOString(),
      id: '' + random(8),
    };
    return dd;
  });

export const dealers: Dealer = {
  createdAt: new Date().toISOString(),
  id: 'dummy-dealer',
  primaryUserId: 'dummy-user',
  address: {
    address1:
      pickOne(lastNames) +
      ' ' +
      pickOne('Street Avenue Way Road Park'.split(' ')),
    city: pickOne(cities),
    postalcode: '' + random(5),
    state: pickOne(cities),
    address2:
      pickOne(lastNames) +
      ' ' +
      pickOne('Street Avenue Way Road Park'.split(' ')),
    country: pickOne(cities),
  },
  url: 'www.latham.com',
  name: pickOne(firstNames) + ' ' + pickOne(lastNames),
  phone: '' + random(8),
  email: 'test@gmail.com',
  rewardsId: '' + random(8),
  isUserDealer: false,
  distributors: distributors,
};
