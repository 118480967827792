import * as yup from 'yup';
import {
  ApplyToAllTread,
  Bead,
  ChooseLaterString,
  Other,
  YES,
  anchorTypeOptions,
  select,
} from './FeatureConstants';
import { FeatureMeasurements } from 'models/Project';
import { FeatureType } from 'models';
import { SelectedOption } from 'models/SelectOptions';
import { MoldedStepFace } from 'models/Feature';

/**
 * Feature schema partial
 * These fields show for each non anchor feature, i.e. for each deck
 * Keep in mind that the name is required, even though it's not exposed to the UI
 */
export const BaseAnchorFormSchema = yup.object().shape({
  id: yup.string(),
  anchorTypeOptions: yup
    .string()
    .required()
    .oneOf(anchorTypeOptions.map((el) => el.value)),
  cableAssembly: yup.boolean().nullable(),
  featureLabel: yup.string().nullable(),
  featureType: yup.string().nullable(),
  featureName: yup.string().nullable(),
  isRemovable: yup.boolean().nullable(),
  isCovered: yup.boolean().nullable(),
  isOverWater: yup.boolean().nullable(),
  stepFastener: yup.string().required(),
  stepMaterialOption: yup
    .string()
    .required()
    .test(
      ChooseLaterString || Other,
      'stepMaterialOption is a required field',
      (val, context) => {
        if (val === ChooseLaterString || val === Other) {
          return context.createError({
            message: 'stepMaterialOption is a required field',
          });
        }
        return true;
      }
    ),
  stepMaterial: yup.string().nullable(),
  stepStripe: yup.string().required(),
  stripeWidth: yup
    .string()
    .nullable()
    .when('stepStripe', {
      is: (stepStripe: string) => stepStripe === YES,
      then: yup.string().required(),
    }),
  notes: yup.string().nullable(),
  measurement: yup.object().nullable(),
  stepComment: yup
    .string()
    .nullable()
    .when('stepFastener', {
      is: (stepFastener: string) => stepFastener === Bead,
      then: yup
        .string()
        .required()
        .test(select, 'tread is a required field', (val, context) => {
          if (context.parent.featureType == 'Vinyl Feature') {
            if (context.parent.stepFastener == Bead) {
              if (val && val.length > 0) {
                if (val && val.includes(ApplyToAllTread)) {
                  return true;
                }
                const values = val.split('\n');
                const treadNumber =
                  context.parent.measurement.step_treads.numTreads;
                if (values.length - 1 === treadNumber) {
                  return true;
                } else {
                  return false;
                }
              }
            } else {
              return true;
            }
          }
          return true;
        }),
    }),
});

export type BaseDeckFormType = {
  id: string;
  anchorTypeOptions: string;
  cableAssembly: boolean;
  featureLabel: string;
  featureType: FeatureType;
  featureName: string;
  isRemovable: boolean;
  isCovered: boolean;
  isOverWater: boolean;
  stepMaterialOption?: string;
  stepMaterial?: string;
  stepStripe?: string;
  stripeWidth?: string;
  differentTreads?: string;
  riserMaterial?: string;
  stepFastener?: string;
  stepComment?: string;
  moldedStepFace?: MoldedStepFace;
  notes: string;
  measurement: FeatureMeasurements | null;
  linerStepMaterial?: SelectedOption;
};

export const VinlyCoverLinerFeatureBaseAnchorFormSchema = yup.object().shape({
  id: yup.string(),
  anchorTypeOptions: yup
    .string()
    .required()
    .oneOf(anchorTypeOptions.map((el) => el.value)),
  cableAssembly: yup.boolean().nullable(),
  featureName: yup.string().nullable(),
  isRemovable: yup.boolean().nullable(),
  isCovered: yup.boolean().nullable(),
  isOverWater: yup.boolean().nullable(),
  stepFastener: yup.string().required(),
  stepMaterialOption: yup.string().required(),
  stepMaterial: yup
    .string()
    .nullable()
    .when('stepMaterialOption', {
      is: (stepMaterialOption: string) =>
        stepMaterialOption !== ChooseLaterString,
      then: yup.string().required(),
    }),
  stepStripe: yup.string().required(),
  stripeWidth: yup
    .string()
    .nullable()
    .when('stepStripe', {
      is: (stepStripe: string) => stepStripe === YES,
      then: yup.string().required(),
    }),
  notes: yup.string().nullable(),
  measurement: yup.object().nullable(),
  moldedStepFace: yup.string().nullable(),
  stepComment: yup
    .string()
    .nullable()
    .when('stepFastener', {
      is: (stepFastener: string) => stepFastener === Bead,
      then: yup
        .string()
        .required()
        .test(select, 'tread is a required field', (val, context) => {
          if (context.parent.featureType == 'Vinyl Feature') {
            if (context.parent.stepFastener == Bead) {
              if (val && val.length > 0) {
                if (val && val.includes(ApplyToAllTread)) {
                  return true;
                }
                const values = val.split('\n');
                const treadNumber =
                  context.parent.measurement.step_treads.numTreads;
                if (values.length - 1 === treadNumber) {
                  return true;
                } else {
                  return false;
                }
              }
            } else {
              return true;
            }
          }
          return true;
        }),
    }),
});
